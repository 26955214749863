import { ReactComponent as BadgeIcon } from 'assets/svg/badge.svg'
import { ReactComponent as BoardIcon } from 'assets/svg/board.svg'
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { ReactComponent as StarIcon } from 'assets/svg/star.svg'

const statisticsList = [
  {
    id: 1,
    title: '4.85 / 5',
    subtitle: 'Enjoyment Score',
    Icon: <StarIcon />,
    text: '84.85% of respondents voted with the top score',
  },
  {
    id: 3,
    title: '13 Sessions',
    subtitle: '3 Hands-on Workshop Labs Held',
    Icon: <BoardIcon />,
    text: 'We dived deep into diverse AWS topics and real-world applications.',
  },
  {
    id: 2,
    title: '320+',
    subtitle: 'On-Site Participants',
    Icon: <UserIcon />,
    text: 'We were delighted to see so many passionate individuals join us, making it a day full of learning, networking, and inspiration!',
  },
  {
    id: 4,
    title: '24+',
    subtitle: 'Future Speakers Count',
    Icon: <BadgeIcon />,
    text: 'Over 24 inspired individuals, motivated by the incredible energy of AWS Community Day Armenia 2024, are ready to share their knowledge as speakers at our future events!',
  },
]

export default statisticsList
