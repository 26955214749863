import FeedbackOne from 'assets/images/feedback/feedbackOne.png'
import FeedbackTwo from 'assets/images/feedback/feedbackTwo.png'
import FeedbackThree from 'assets/images/feedback/feedbackThree.png'
import FeedbackFour from 'assets/images/feedback/feedbackFour.png'
import FeedbackFive from 'assets/images/feedback/feedbackFive.png'
import FeedbackSix from 'assets/images/feedback/feedbackSix.png'
import FeedbackSeven from 'assets/images/feedback/feedbackSeven.png'
import FeedbackEight from 'assets/images/feedback/feedbackEight.png'
import FeedbackNine from 'assets/images/feedback/feedbackNine.png'
import FeedbackTen from 'assets/images/feedback/feedbackTen.png'
import FeedbackEleven from 'assets/images/feedback/feedbackEleven.png'
import FeedbackTwelve from 'assets/images/feedback/feedbackTwelve.png'
import FeedbackThirteen from 'assets/images/feedback/feedbackThirteen.png'
import FeedbackFourteen from 'assets/images/feedback/feedbackFourteen.png'
import FeedbackFifteen from 'assets/images/feedback/feedbackFifteen.png'
import FeedbackSixteen from 'assets/images/feedback/feedbackSixteen.png'
import FeedbackSeventeen from 'assets/images/feedback/feedbackSeventeen.png'
import FeedbackEighteen from 'assets/images/feedback/feedbackEighteen.png'
import FeedbackNineteen from 'assets/images/feedback/feedbackNineteen.png'

const feedbackList = [
  {
    id: 1,
    image: FeedbackOne,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7241059481218871296/?actorCompanyId=93086989',
  },
  {
    id: 2,
    image: FeedbackTwo,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7241339263093534720/?actorCompanyId=93086989',
  },
  {
    id: 3,
    image: FeedbackFive,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7243296183530459136/?actorCompanyId=93086989',
  },
  {
    id: 4,
    image: FeedbackThree,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7241732728436510720/?actorCompanyId=93086989',
  },
  {
    id: 5,
    image: FeedbackFour,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7242192019182292995/?actorCompanyId=93086989',
  },
  {
    id: 6,
    image: FeedbackSix,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7245140658380828672/?actorCompanyId=93086989',
  },
  {
    id: 7,
    image: FeedbackSeven,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7251862199793541122/?actorCompanyId=93086989',
  },
  {
    id: 8,
    image: FeedbackEight,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240724846416973825/?actorCompanyId=93086989',
  },
  {
    id: 9,
    image: FeedbackNine,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240741663789232128/?actorCompanyId=93086989',
  },
  {
    id: 10,
    image: FeedbackEleven,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7242636527850192896/?actorCompanyId=93086989',
  },
  {
    id: 11,
    image: FeedbackTen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7242209523086557184/?actorCompanyId=93086989',
  },
  {
    id: 12,
    image: FeedbackTwelve,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7243378954231758848/?actorCompanyId=93086989',
  },
  {
    id: 13,
    image: FeedbackThirteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7244347102330290178/?actorCompanyId=93086989',
  },
  {
    id: 14,
    image: FeedbackFourteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7244696389912268800/?actorCompanyId=93086989',
  },
  {
    id: 15,
    image: FeedbackFifteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7250782442565881856/?actorCompanyId=93086989',
  },
  {
    id: 16,
    image: FeedbackSixteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240804787519119361/?actorCompanyId=93086989',
  },
  {
    id: 17,
    image: FeedbackNineteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240648123432210432/?actorCompanyId=93086989',
  },
  {
    id: 18,
    image: FeedbackSeventeen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7240707454785777666/?actorCompanyId=93086989',
  },
  {
    id: 19,
    image: FeedbackEighteen,
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7241303502247931904/?actorCompanyId=93086989',
  },
]

export default feedbackList
