import styles from './FeedbackCard.module.scss'

const FeedbackCard = ({ data }: any) => {
  const { image, link } = data

  return (
    <div className={styles.wrapper}>
      <img src={image} />
      <a href={link} target='_blanck' rel='noreferrer'>
        View Post
      </a>
    </div>
  )
}

export default FeedbackCard
